import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Head from "../components/head"
import Layout from "../components/layout"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      blogImage {
        file {
          url
        }
        description
      }
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      seoTitle
      seoDescription

      body {
        json
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img src={url} alt={alt} />
      },
    },
  }
  return (
    <Layout title={props.data.contentfulBlogPost.title} btnText="" btnLink="" backgroundImage={props.data.contentfulBlogPost.blogImage.file.url}>
      <Head title={props.data.contentfulBlogPost.seoTitle} />
      <section>
        <wrapper>
         {/* <img
            src={props.data.contentfulBlogPost.blogImage.file.url}
            alt={props.data.contentfulBlogPost.blogImage.description}
          />
         <h2>{props.data.contentfulBlogPost.title}</h2>*/}
          <p>{props.data.contentfulBlogPost.publishedDate}</p>
          {documentToReactComponents(
            props.data.contentfulBlogPost.body.json,
            options
          )}
        </wrapper>
      </section>
    </Layout>
  )
}

export default Blog
